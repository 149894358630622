@font-face {
font-family: 'exo';
src: url(/_next/static/media/b5fc62ec03a89a50-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'exo';
src: url(/_next/static/media/04b58be6b4137073-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'exo';
src: url(/_next/static/media/12bdaef7f8cea161-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'exo Fallback';src: local("Arial");ascent-override: 95.72%;descent-override: 31.24%;line-gap-override: 0.00%;size-adjust: 104.68%
}.__className_ad49fb {font-family: 'exo', 'exo Fallback'
}

